import React, { useEffect } from "react";
function Team() {
    // let refreshId = -1;
    // let innerWidth = window.innerWidth;
    // let innerHeight = window.innerHeight;

    const teamData = [{
        "TeamMemberId": 3,
        "Profession": null,
        "Name": "John Ruiz",
        "JobTilte": "CEO & Founder",
        "LinkedInUrl": "https://www.linkedin.com/in/jhonruizt",
        "CurriculumUrl": "jhonruizt",
        "Email": "john.ruiz@rhinoitsolutions.com",
        "PhoneNumber": null,
        "CurriculumPDFUrl": null,
        "Photo": "assets/images/TeamMembers/jhonruizt.jpg",
        "CurriculumProfileText": null,
        "CVBackgound": null,
        "Education": [],
        "Experience": [],
        "Skill": []
    },
    {
        "TeamMemberId": 5,
        "Profession": null,
        "Name": "Deisy Doncel",
        "JobTilte": "Business Developer",
        "LinkedInUrl": "https://www.linkedin.com/in/deisydoncel",
        "CurriculumUrl": "deisydoncel",
        "Email": "deisy.doncel@rhinoitsolutions.com",
        "PhoneNumber": null,
        "CurriculumPDFUrl": null,
        "Photo": "assets/images/TeamMembers/deisydoncel.jpg",
        "CurriculumProfileText": null,
        "CVBackgound": null,
        "Education": [],
        "Experience": [],
        "Skill": []
    },
    {
        "TeamMemberId": 6,
        "Profession": null,
        "Name": "Andrés Torres",
        "JobTilte": "Chief Technology Officer",
        "LinkedInUrl": null,
        "CurriculumUrl": null,
        "Email": "andres.torres@rhinoitsolutions.com",
        "PhoneNumber": null,
        "CurriculumPDFUrl": null,
        "Photo": "assets/images/TeamMembers/andrestorres.png",
        "CurriculumProfileText": null, "CVBackgound": null, "Education": [], "Experience": [], "Skill": []
    }]
    useEffect(() => {
        window.$("#owl-team").owlCarousel({
            items: 2,
            navigation: true,
            pagination: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            autoPlay: false,
        });

        // window.$('#team-header').parallax({ src: `assets/images/parallax-team.jpg` })

        // window.addEventListener("resize", refreshParallax);
        // window.addEventListener("scroll", refreshParallax);
    });

    // const refreshParallax = () => {
    //     if (refreshId === -1) {

    //         window.setTimeout(() => {
    //             if (window.innerWidth !== innerWidth || window.innerHeight !== innerHeight)
    //                 refreshId = window.setTimeout(() => {
    //                     window.$('#team-header').parallax('refresh');
    //                     window.$('#team-header').parallax('render');
    //                     refreshId = -1;
    //                     innerWidth = window.innerWidth;
    //                     innerHeight = window.innerHeight;
    //                 }, 100)
    //         }, 200)
    //     }
    // }

    const renderTeamMember = (item, i) => {
        return (
            <div className="owl-item" key={Math.random()}>
                <div style={{ boxShadow: 'rgba(160, 160, 160, 0.2) 0px 0px 10px', borderRadius: 12, margin: '15px 30px 15px 15px', padding: 10, background: '#454f5f0f' }}>
                    <div className="member">
                        <img src={`${item.Photo}`} style={{ maxWidth: '50%', width: 130 }} alt="" />
                        <div className="member-data">
                            <h3>{item.Name}</h3>
                            <span>{item.JobTilte}</span>
                        </div>
                        <ul className="icons-widget icons-widget-sm">
                            {item.LinkedInUrl &&
                                <li>
                                    <a href={item.LinkedInUrl} target="_blank">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                            }
                            {item.CurriculumUrl &&
                                <li>
                                    <a href={`Curriculum/${item.CurriculumUrl}`} target="_balnk">
                                        <i className="fas fa-globe"></i>
                                    </a>
                                </li>
                            }
                            <li>
                                <a href={`mailto:${item.Email}`}>
                                    <i className="fas fa-at"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        )
    }

    return (
        <div>
            {/* <section id="team-header" className="section-header">
                <div className="color-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 style={{ fontWeight: 'bold' }}>Nuestro Equipo</h2>
                                <p >Podrías trabajar con alguno de nostros.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section id="team" className="section">
                <div className="container  wow fadeInUp faster" style={{ animationDelay: '400ms' }}>
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <h2 style={{ fontWeight: 'bold' }}>Nuestro Equipo</h2>
                        </div>
                    </div>
                </div>
                <div className="container ">

                    <div className="row">
                        <div className="col-md-6 illustration wow fadeIn" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 420 }}>
                            <img src={`assets/images/illustrations/teamspirit.svg`} style={{ height: 420, maxWidth: '80%' }} alt="" />
                        </div>
                        <div className="col-md-6 wow zoomIn faster" style={{ display: 'flex', alignItems: 'center', height: 420, animationDelay: '800ms' }}>
                            <div id="owl-team" className="owl-carousel">
                                {teamData.map(renderTeamMember)}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Team;
import React, { useEffect, useState } from "react";

function Plans() {
    const [showFeatures, setShowFeatures] = useState(false);
    const [animateOut, setanimateOut] = useState(false);

    const plans = [
        {
            name: "Basic",
            price: 349,
            additionalAgent: 10,
            includdedAgents: 5,
            featuresDescription: 'Características',
            features: [
                'Hasta 5 aplicaciones',
                'Diseñador de formularios',
                'Administración de Perfiles',
                'Diseñador de flujos BPM',
                'Reportes de aplicación Básicos',
                'BPM SLAs'
            ],
            messageFromOperator: 'Esta interesado en el plan Básico?',
            anchor: 4,
            customaClass: 'basic',
            animationDelay: '600ms'
        },
        {
            name: "Business",
            price: 599,
            additionalAgent: 10,
            includdedAgents: 30,
            featuresDescription: 'Plan Basic +',
            features: [
                'Hasta 20 aplicaciones',
                'Integracion SOA y REST',
                'Gestor Documental Básico',
                'Integración con Google Account',
                'Integración con Azure AD',
                'API de integración (2.000 llamados)'
            ],
            messageFromOperator: 'Esta interesado en el plan Business?',
            anchor: 4,
            customaClass: 'business',
            animationDelay: '500ms'
        },
        {
            name: "Enterprise",
            price: 1099,
            additionalAgent: 10,
            includdedAgents: 100,
            featuresDescription: 'Plan Business +',
            features: [
                'Sin límite de aplicaciones',
                'Logs de auditoría',
                'Gestor Documental Avanzado',
                'Visualizador de casos BPM',
                'Sin Límite de llamados al API de integración',
                'RhinoFlow Web CLI'
            ],
            messageFromOperator: 'Esta interesado en el plan Enterprise?',
            anchor: 4,
            customaClass: 'enterprise',
            animationDelay: '600ms'
        },
    ]
    const categories = [
        {
            categoryName: "Modelador BPM", items: [
                { name: "Modelador BPMN 2.0", basic: true, business: true, enterprise: true },
                { name: "Validación de modelado", basic: false, business: true, enterprise: true },
                { name: "Integración con servicios SOA y REST", basic: false, business: true, enterprise: true },
                { name: "Simulación de Token", basic: false, business: false, enterprise: true },
                { name: "SLA sobre BPM", basic: false, business: false, enterprise: true },
            ]
        },
        {
            categoryName: "Formularios Dinámicos", items: [
                { name: "Diseñador de formularios drag and drop", basic: true, business: true, enterprise: true },
                { name: "Scripting sobre formularios", basic: false, business: true, enterprise: true },
                { name: "Catálogos sobre listas desplegables", basic: false, business: true, enterprise: true },
                { name: "Carga desde servicios externos", basic: false, business: false, enterprise: true },
            ]
        },
        {
            categoryName: "Seguridad", items: [
                { name: "Seguridad basada en roles (RBAC)", basic: true, business: true, enterprise: true },
                { name: "Integración con Azure Active Directory", basic: false, business: false, enterprise: true },
                { name: "Integración con Google Account", basic: false, business: false, enterprise: true },
            ]
        },
        {
            categoryName: "Gestor Documental", items: [
                { name: "Gestor Documental sobre BPM", basic: false, business: true, enterprise: true },
                { name: "Validación de documental sobre User Taks", basic: false, business: false, enterprise: true },
                { name: "Historial de documentos", basic: false, business: false, enterprise: true },
            ]
        },
        {
            categoryName: "Colaboración", items: [
                { name: "Live Chat", basic: true, business: true, enterprise: true },
                { name: "Live Chat de casos", basic: false, business: false, enterprise: true },
            ]
        },
    ]

    useEffect(() => {
        var observer = new IntersectionObserver((records, observer) => {
            if (records[0].intersectionRatio === 0) {
                document.querySelector('#btn-basic').classList.remove('hide-button')
                document.querySelector('#btn-business').classList.remove('hide-button')
                document.querySelector('#btn-enterprise').classList.remove('hide-button')
                document.querySelector('#sticky-container').parentNode.classList.add('header-sticky')
            }
            else if (records[0].intersectionRatio === 1) {
                document.querySelector('#btn-basic').classList.add('hide-button')
                document.querySelector('#btn-business').classList.add('hide-button')
                document.querySelector('#btn-enterprise').classList.add('hide-button')
                document.querySelector('#sticky-container').parentNode.classList.remove('header-sticky')
            }
        }, { threshold: [0] });

        observer.observe(document.querySelector('#sticky-container'))
        window.document.addEventListener('sticky-change', e => {
            debugger;
        });
        window.$("#about-products").owlCarousel({
            items: 1,
            navigation: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            pagination: true,
            autoPlay: 15000,
            singleItem: true
        });

    });

    return (
        <div>
            <section id="plans" className="section">
                <div className="container  wow zoomIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <h2>Planes</h2>
                            <p>Encuentre una plan que se ajuste a sus necesidades.</p>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row" style={{ marginBottom: 10 }}>
                        {plans.map((plan) => {
                            return (
                                <div className={`col-md-${plan.anchor} wow zoomIn faster`} style={{ animationDelay: plan.animationDelay }}>
                                    <div className={`plan ${plan.customaClass}`} >
                                        <div>{' '}<h3>{plan.name}</h3></div>
                                        <div className="pricing">
                                            <h2>{`$${plan.price}`}
                                                <p>/mes</p>
                                            </h2>
                                            <br />
                                            <h2>{`$${plan.additionalAgent}`}
                                                <p>/agente adicional</p>
                                            </h2>
                                            <h2>
                                                <p>{`Incluye ${plan.includdedAgents} Agentes`}</p>
                                            </h2>
                                        </div>
                                        <h2>
                                            <p>{plan.featuresDescription}</p>
                                        </h2>
                                        <div className="features">
                                            {plan.features.map((feature) => {
                                                return (
                                                    <div className="feature">
                                                        <img src={`assets/images/serviceslogos/check.svg`} alt='' />
                                                        <p style={{ textAlign: 'left' }}>
                                                            {` ${feature}`}
                                                        </p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="feature">
                                            <button onClick={() => {
                                                window.frames["tidioChatApi"].open();
                                                window.frames["tidioChatApi"].messageFromOperator(`${plan.messageFromOperator}`);
                                            }}>Solicitar Demo</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="compare-container">
                            <div className="compare-btn-container col-md-12 wow zoomIn faster" style={{ animationDelay: '200ms' }}>
                                <button className="compare-btn" onClick={() => {
                                    if (showFeatures) {
                                        setanimateOut(true)
                                        window.setTimeout(() => {
                                            setShowFeatures(false)
                                            setanimateOut(false)
                                        }, 500)
                                    }
                                    else
                                        setShowFeatures(true)
                                }}>{'Comparar planes y características'}<i style={{ marginLeft: 10 }} className={showFeatures ? "fa fa-eye-slash" : "fa fa-arrow-down"}></i></button>
                            </div>
                            <div className={`col-md-12 animated ${animateOut ? 'fadeOutDown' : 'fadeInUp'} fast compare-table`} style={{ display: showFeatures ? 'unset' : 'none' }}>
                                <div style={{ height: 1 }} id="sticky-container"></div>
                                <table>
                                    <thead>
                                        <tr >
                                            <th>Características</th>
                                            <th >Basic
                                            <button id="btn-basic" className={`hide-button animated fadeIn fast`} onClick={() => {
                                                    window.frames["tidioChatApi"].open();
                                                    window.frames["tidioChatApi"].messageFromOperator("Estas interesado en el plan Básico?");
                                                }}>Solicitar Demo</button>
                                            </th>
                                            <th className="business">Business
                                        <button id="btn-business" className={`hide-button animated fadeIn fast`} onClick={() => {
                                                    window.frames["tidioChatApi"].open();
                                                    window.frames["tidioChatApi"].messageFromOperator("Estas interesado en el plan Business?");
                                                }}>Solicitar Demo</button>
                                            </th>
                                            <th className="enterprise">Enterprise
                                        <button id="btn-enterprise" className={`hide-button animated fadeIn fast`} onClick={() => {
                                                    window.frames["tidioChatApi"].open();
                                                    window.frames["tidioChatApi"].messageFromOperator("Estas interesado en el plan Enterprise?");
                                                }}>Solicitar Demo</button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categories.map((item, catIndex) => {
                                            return (
                                                <React.Fragment>
                                                    <tr key={catIndex} style={{ background: 'none' }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10 }}><img style={{ height: 18, marginTop: -5 }} src={`assets/images/serviceslogos/007-bulleye.svg`} alt='' />{' '}{item.categoryName}</td>
                                                    </tr>
                                                    {item.items.map((feature, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{feature.name}</td>
                                                                <td>{feature.basic ? <img src={`assets/images/serviceslogos/check.svg`} alt='' /> : <img src={`assets/images/serviceslogos/remove.svg`} alt='' />}</td>
                                                                <td>{feature.business ? <img src={`assets/images/serviceslogos/check.svg`} alt='' /> : <img src={`assets/images/serviceslogos/remove.svg`} alt='' />}</td>
                                                                <td>{feature.enterprise ? <img src={`assets/images/serviceslogos/check.svg`} alt='' /> : <img src={`assets/images/serviceslogos/remove.svg`} alt='' />}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </React.Fragment>
                                            )

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Plans;
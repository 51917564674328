import React, { useEffect } from "react";
function Clients() {

    const logoData = [{
        "Name": "CiberGestión",
        "Logo": "assets/images/logo/clients/CiberLogo.svg"
    },
    {
        "Name": "Crowdy Messaging", 
        "Logo": "assets/images/logo/clients/crowdylogotextblack.svg"
    },
    ];
    const commentsData = [{ "IdClientComment": 1, "Author": "Victor Casachaua", "JobTitle": "IT Leader en Cibergestión", "Comment": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam", "Stars": 5, "Picture": "assets/images/CommentAuthors/victorcasachaua.jpg" }, { "IdClientComment": 3, "Author": "Jack Woods", "JobTitle": "Development, SomeCompany", "Comment": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam", "Stars": 3, "Picture": "assets/images/CommentAuthors/128(2).jpg" }];
    // let refreshId = -1;
    // let innerWidth = window.innerWidth;
    // let innerHeight = window.innerHeight;


    useEffect(() => {
        window.$("#owl-clients").owlCarousel({
            items: 2,
            pagination: true,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            navigation: false,
            slideSpeed: 300,
            paginationSpeed: 1000,
            autoPlay: true
        });

        window.$("#owl-testimonials").owlCarousel({
            items: 1,
            navigation: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            autoPlay: false,
            singleItem: true
        });

        // window.$('#clients-header').parallax({ src: `assets/images/parallax-client.jpg` })

        // window.addEventListener("resize", refreshParallax);
        // window.addEventListener("scroll", refreshParallax);
    });

    // const refreshParallax = () => {
    //     if (refreshId === -1) {

    //         window.setTimeout(() => {
    //             if (window.innerWidth !== innerWidth || window.innerHeight !== innerHeight)
    //                 refreshId = window.setTimeout(() => {
    //                     window.$('#clients-header').parallax('refresh');
    //                     window.$('#clients-header').parallax('render');
    //                     refreshId = -1;
    //                     innerWidth = window.innerWidth;
    //                     innerHeight = window.innerHeight;
    //                 }, 100)
    //         }, 200)
    //     }
    // }

    const renderLogos = (item, i) => {
        return (
            <div className="owl-item" key={Math.random()}>
                <img src={`${item.Logo}`} style={{ height: 64 }} alt="" className="grayimg" />
            </div>
        )
    }

    const renderComments = (item, i) => {

        var stars = [];

        for (let i = 0; i < item.Stars; i++)
            stars.push(<i className="fa fa-star" key={Math.random()}></i>)

        if (item.Stars < 5) {
            let blackStars = 5 - item.Stars

            for (let i = 0; i < blackStars; i++)
                stars.push(<i className="fa fa-star star-off" key={Math.random()}></i>)
        }

        return (
            <div className="owl-item" key={Math.random()} >
                <div style={{ boxShadow: 'rgba(192, 192, 192, 0.46) 0px 0px 10px', borderRadius: 8, margin: '15px 30px 15px 15px', padding: 10, background: '#454f5f0f' }}>
                    <div className="avatar">
                        <img src={`${item.Picture}`} alt="" />
                    </div>
                    <blockquote>
                        <h4>{item.Author}</h4>
                        <h6>{item.JobTitle}</h6>
                        <p style={{ fontSize: 13, textAlign: 'left' }}>{item.Comment}</p>
                    </blockquote>
                    <div className="stars">
                        {stars}
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div>
            {/* <section id="clients-header" className="section-header">
                <div className="color-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 style={{ fontWeight: 'bold' }}>Nuestros Clientes</h2>
                                <p >Trabajamos con pasión para estas exitosas empresas.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section id="clients" className="section rotate-section">
                <div className="wow fadeInUp faster" style={{ animationDelay: '400ms' }}>
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <h2 style={{ fontWeight: 'bold' }}>Clientes</h2>
                            <p >Trabajamos con pasión para estas exitosas empresas.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row" key={Math.random()}>
                        <div className="col-md-6 illustration  wow fadeIn" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 420 }}>
                            <img src={`assets/images/illustrations/addcart.svg`} style={{ maxWidth: '80%' }} alt="" />
                        </div>
                        <div className="col-md-6 wow zoomIn faster" style={{ display: 'flex', alignItems: 'center', height: 420, animationDelay: '800ms'  }}>
                            <div id="owl-clients" className="owl-carousel" style={{ margin: '50px 0px' }} >
                                {logoData && logoData.map(renderLogos)}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="wow zoomIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <h2>Comentarios</h2>
                            <p>Esto es lo que opinan algunos de nuestros clientes.</p>
                        </div>
                    </div>
                </div>
               

                <div className="container wow zoomIn faster" style={{ animationDelay: '400ms' }}>
                    <div className="row" key={Math.random()}>
                        <div className="col-md-6 " style={{ display: 'flex', alignItems: 'center', height: 420 }}>
                            <div id="owl-testimonials" className="owl-carousel">
                                {commentsData && commentsData.map(renderComments)}
                            </div>
                        </div>
                        <div className="col-md-6 illustration" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 420 }}>
                            <img src={`assets/images/illustrations/wallpost.svg`} style={{ height: 420, maxWidth: '80%' }} alt="" />
                        </div>
                    </div>
                </div> */}
            </section>
        </div>
    )
}

export default Clients;
import React from 'react'
import ReactTextRotator from 'react-text-rotator';
import Particles from 'react-particles-js';

function Intro() {

    return (
        <section id="intro" className="section">
            <Particles style={{ position: 'absolute', left: 0, top: 0 }}
                params={{
                    "particles": {
                        "number": {
                            "value": 10,
                            "density": {
                                "enable": false
                            }
                        },
                        "size": {
                            "value": 6,
                            "random": true,
                            "anim": {
                                "speed": 0.4,
                                "size_min": 0.3
                            }
                        },
                        "line_linked": {
                            "enable": false
                        },
                        "move": {
                            "random": true,
                            "speed": 0.4,
                            "direction": "top",
                            "out_mode": "out"
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "bubble"
                            },
                            "onclick": {
                                "enable": true,
                                "mode": "repulse"
                            }
                        },
                        "modes": {
                            "bubble": {
                                "distance": 250,
                                "duration": 2,
                                "size": 0,
                                "opacity": 0
                            },
                            "repulse": {
                                "distance": 400,
                                "duration": 4
                            }
                        }
                    }
                }}
            ></Particles>

            <div className="container" id="particlejs">
                <div className="row">
                    <div className="col-md-12">
                        {/* <Particles
                             params={{
                                "fps_limit": 28,
                                "particles": {
                                    "collisions": {
                                        "enable": false
                                    },
                                    "number": {
                                        "value": 200,
                                        "density": {
                                            "enable": false
                                        }
                                    },
                                    "line_linked": {
                                        "enable": true,
                                        "distance": 30,
                                        "opacity": 0.4
                                    },
                                    "move": {
                                        "speed": 1
                                    },
                                    "opacity": {
                                        "anim": {
                                            "enable": true,
                                            "opacity_min": 0.05,
                                            "speed": 1,
                                            "sync": false
                                        },
                                        "value": 0.4
                                    }
                                },
                                "polygon": {
                                    "enable": true,
                                    "scale": 0.5,
                                    "type": "inline",
                                    "move": {
                                        "radius": 10
                                    },
                                    "url": "/small-deer.2a0425af.svg",
                                    "inline": {
                                        "arrangement": "equidistant"
                                    },
                                    "draw": {
                                        "enable": true,
                                        "stroke": {
                                            "color": "rgba(255, 255, 255, .2)"
                                        }
                                    }
                                },
                                "retina_detect": false,
                                "interactivity": {
                                    "events": {
                                        "onhover": {
                                            "enable": true,
                                            "mode": "bubble"
                                        }
                                    },
                                    "modes": {
                                        "bubble": {
                                            "size": 6,
                                            "distance": 40
                                        }
                                    }
                                }}}
                            /> */}
                        <div className="hello wow zoomIn faster">
                            <h3 style={{ textShadow: '7px 1px 20px #000' }}>Bienvenido</h3>
                            <br />
                            <h4 style={{ textShadow: '7px 1px 20px #000' }}>
                                Ponemos a su disposición nuestra experiencia, pasión e innovación creando soluciones de alta calidad, para que pueda
                                enfocar sus esfuerzos en el crecimiento de su negocio.
                                </h4>
                            <br />
                            <h3>
                                <ReactTextRotator
                                    content={[
                                        {
                                            text: 'Pasión',
                                            animation: 'fade',
                                        },
                                        {
                                            text: 'Innovación',
                                            animation: 'fade',
                                        },
                                        {
                                            text: 'Calidad',
                                            animation: 'fade',
                                        },
                                    ]}
                                    time={3000}
                                    startDelay={0}
                                />
                                {/* <span style={{ textShadow: '7px 1px 20px #000 !important' }} className="rotate" >Pasión| Innovación | Calidad</span> */}
                            </h3>
                        </div>
                        <a href="#products" style={{position: 'relative'}}>
                            <div className="icon-scroll" style={{ marginTop: -50 }}>
                                {/* <div className="wheel"></div> */}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Intro;
import React, { useEffect } from "react";

function About() {

    // let refreshId = -1;
    // let innerWidth = window.innerWidth;
    // let innerHeight = window.innerHeight;

    // const refreshParallax = () => {
    //     if (refreshId === -1) {

    //         window.setTimeout(() => {
    //             if (window.innerWidth !== innerWidth || window.innerHeight !== innerHeight)
    //                 refreshId = window.setTimeout(() => {
    //                     window.$('#about-header').parallax('refresh');
    //                     window.$('#about-header').parallax('render');
    //                     refreshId = -1;
    //                     innerWidth = window.innerWidth;
    //                     innerHeight = window.innerHeight;
    //                 }, 100)
    //         }, 200)
    //     }
    // }

    useEffect(() => {
        window.$("#about-products").owlCarousel({
            items: 1,
            navigation: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            pagination: true,
            autoPlay: 15000,
            singleItem: true
        });

        // window.$('#about-header').parallax({ src: `assets/images/parallax2.jpg` })

        // window.addEventListener("resize", refreshParallax);
        // window.addEventListener("scroll", refreshParallax);
    });

    return (
        <div>
            {/* <section id="about-header" className="section-header" >
                <div className="color-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2>Nosotros</h2>
                                <p>Acerca de nuestros valores y motivaciones.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section id="about" className="section">
                <div className="container  wow fadeInUp faster" style={{ animationDelay: '400ms' }}>
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <h2>Nosotros</h2>
                            <p>Acerca de nuestros valores y motivaciones.</p>
                        </div>
                    </div>
                </div>
                <div className="container" >
                    <div className="row">
                        <div className="col-md-6 illustration wow fadeIn" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 420 }}>
                            <img src={`assets/images/illustrations/team.svg`} style={{ maxWidth: '80%' }} alt="" />
                        </div>
                        <div className="col-md-6 wow zoomIn faster" style={{ display: 'flex', alignItems: 'center', height: 420, animationDelay: '800ms' }}>
                            <div className="col-md-12">
                                <div className="service" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <h3>Quienes Somos</h3>
                                    <div className="text">
                                        <p style={{ textAlign: 'justify' }}>
                                            Somos un grupo de profesionales apasionados por su labor, queremos que esta se vea reflejada en todo lo que hacemos para nuestros clientes, nuestra oferta de valor consiste en ver más allá del problema para que la solución sobrepase todas las expectativas, además le ofrecemos acompañamiento continuo para lo que su negocio requiera.</p>

                                        <p style={{ textAlign: 'justify' }}>
                                            Tenemos en mente una premisa, la calidad en todas nuestras entregas, esto lo logramos aplicando las mejores prácticas en todo lo que hacemos.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;
import React, { useEffect } from 'react';
import Intro from './home/Intro'
import MainHeader from './home/MainHeader'
import Services from './home/Services'
import Products from './home/Products'
import Technologies from './home/Technologies'
import Preloader from './home/Preloader'
import ScrollUp from './home/ScrollUp'
import About from './home/About'
import Clients from './home/Clients'
import Footer from './home/Footer'
import Team from './home/Team'
import Contact from './home/Contact'

function App() {
  const scrollUpCheck = () => {
    if (window.$(window).scrollTop() > 100) {
      window.$('.scroll-up').fadeIn();
    } else {
      window.$('.scroll-up').fadeOut();
    }
  }

  useEffect(() => {
    window.$('#intro').backstretch([
      // '/assets/images/bglogin15.svg',
      // '/assets/images/Spectrum-Gradient.svg',
      // '/assets/images/Abstract-Envelope.svg',
      // '/assets/images/Sun-Tornado.svg',
      '/assets/images/1.jpg',
      // '/assets/images/2.jpg',
      // '/assets/images/3.jpg',
    ], { duration: 8000, fade: 1000 });

    window.$('body').scrollspy({
      target: '.navbar-custom',
      offset: 70
    })

    var navbar = window.$('.navbar');
    var navHeight = navbar.height();

    window.$(window).scroll(function () {
      if (window.$(this).scrollTop() >= navHeight) {
        navbar.addClass('navbar-color');
      }
      else {
        navbar.removeClass('navbar-color');
      }
    });

    if (window.$(window).width() <= 767) {
      navbar.addClass('custom-collapse');
    }

    window.$(window).resize(function () {
      if (window.$(this).width() <= 767) {
        navbar.addClass('custom-collapse');
      }
      else {
        navbar.removeClass('custom-collapse');
      }
    });

    window.$(document).on('click', '.navbar-collapse.in', function (e) {
      if (window.$(e.target).is('a') && window.$(e.target).attr('class') !== 'dropdown-toggle') {
        window.$(this).collapse('hide');
      }
    });

    window.$('a[href*=#]').bind("click", function (e) {
      var anchor = window.$(this);
      if (window.$(anchor.attr('href')).offset()) {
        window.$('html, body').stop().animate({
          scrollTop: window.$(anchor.attr('href')).offset().top
        }, 1000);
      }
      e.preventDefault();
    });

    scrollUpCheck();
    window.$(window).scroll(function () {
      scrollUpCheck();
    });

    let wow = new window.WOW({
      mobile: true
    });
    wow.init();
    window.$('#preloader').fadeOut('slow');

  }, [])

  return (
    <div >
      <div style={{ overflow: 'hidden' }}>
        <Preloader></Preloader>
        <ScrollUp></ScrollUp>
        <Intro></Intro>
        <Services></Services>
        <Products></Products>
        <Technologies></Technologies>
        <About></About>
        <Clients></Clients>
        <Team></Team>
      </div>
    </div>

  );
}

export default App;

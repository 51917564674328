import React, { useEffect } from 'react'

function Products() {
    // let refreshId = -1;
    // let innerWidth = window.innerWidth;
    // let innerHeight = window.innerHeight;

    // const refreshParallax = () => {
    //     if (refreshId === -1) {

    //         window.setTimeout(() => {
    //             if (window.innerWidth !== innerWidth || window.innerHeight !== innerHeight)
    //                 refreshId = window.setTimeout(() => {
    //                     window.$('#products-header').parallax('refresh');
    //                     window.$('#products-header').parallax('render');
    //                     refreshId = -1;
    //                     innerWidth = window.innerWidth;
    //                     innerHeight = window.innerHeight;
    //                 }, 100)
    //         }, 200)
    //     }
    // }

    useEffect(() => {
        window.$("#owl-products").owlCarousel({
            items: 1,
            navigation: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            pagination: true,
            autoPlay: 15000,
            singleItem: true
        });

        // window.$('#products-header').parallax({ src: `assets/images/back (3).jpg` })

        // window.addEventListener("resize", refreshParallax);
        // window.addEventListener("scroll", refreshParallax);
    });


    return (
        <div>
            {/* <section id="products-header" className="section-header">
                <div className="color-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 style={{ fontWeight: 'bold' }}>Productos</h2>
                                <p >Productos que nos enorgullecen.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section id="products" className="section">
                <div className="container  wow fadeInUp faster" style={{ animationDelay: '400ms' }}>
                    <div className="row">
                        <div className="col-sm-12" style={{marginTop: 30, textAlign: 'center', color:'#364153'}}>
                            <h2 style={{ fontWeight: 'bold' }}>Productos</h2>
                            <p >Productos que nos enorgullecen.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 illustration wow fadeIn" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 420 }}>
                            <img src={`assets/images/illustrations/products.svg`} style={{ maxWidth: '80%' }} alt="" />
                        </div>
                        <div className="col-md-6 wow zoomIn faster" style={{ height: 420, display: 'flex', alignItems: 'center', animationDelay: '800ms' }} >
                            <div id="owl-products" className="owl-carousel">
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/logo/RhinoFlowTextNew.svg`} style={{ height: 100, maxWidth: '70%'}} alt="" />
                                    <h4>{'Rhino Flow BPMs'}</h4>
                                    <div className="text">
                                        <p >{'Automatice los procesos de su compañía con una plataforma flexible y escalable compatible con la Nube y lleve al siguiente nivel la efectividad de sus operaciones.'}<br /><br /><strong style={{ cursor: 'pointer' }}>Mas información</strong></p>
                                    </div>
                                </div>
                                {/* <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/logo/Messaging Services.svg`} style={{ height: 100, maxWidth: '70%' }} alt="" />
                                    <h4>{'Messaging Services'}</h4>
                                    <div className="text">
                                        <p >{'Envie mensajeria SMS con altos volumenes transaccionales e interacciones bidireccionales, integre facilmente con nuestra plataforma por medio de API\'s de alto desempeño y monitoree todo en tiempo real '}<br /><br /><strong style={{ cursor: 'pointer' }}>ver más</strong></p>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div >
    );
}

export default Products
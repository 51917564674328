import React from 'react'

function ScrollUp() {

    return (
        <div className="scroll-up animated zoomInLeft fast">
            <a href="#intro">
                <i className="fa fa-arrow-up"></i>
            </a>
        </div>
    );
}

export default ScrollUp;
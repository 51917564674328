import React, { useEffect } from "react";

function Contact() {
    // let refreshId = -1;
    // let innerWidth = window.innerWidth;
    // let innerHeight = window.innerHeight;

    // const refreshParallax = () => {
    //     if (refreshId === -1) {

    //         window.setTimeout(() => {
    //             if (window.innerWidth !== innerWidth || window.innerHeight !== innerHeight)
    //                 refreshId = window.setTimeout(() => {
    //                     window.$('#products-header').parallax('refresh');
    //                     window.$('#products-header').parallax('render');
    //                     refreshId = -1;
    //                     innerWidth = window.innerWidth;
    //                     innerHeight = window.innerHeight;
    //                 }, 100)
    //         }, 200)
    //     }
    // }

    // useEffect(() => {
    //     window.$('#contact-header').parallax({ src: `assets/images/parallax-contact.jpg` })

    //     window.addEventListener("resize", refreshParallax);
    //     window.addEventListener("scroll", refreshParallax);
    // });


    return (
        <div>
            <div>
                {/* <section id="contact-header" className="section-header">
                <div className="color-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 animated bounceInDown">
                                <h2 style={{ fontWeight: 'bold' }}>Contactanos</h2>
                                <p >Envíanos una mensaje o llámanos. Nos encantará escucharte.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
                <section id="contact" className="section rotate-section">
                    <div className="container  wow zoomIn faster" style={{ animationDelay: '200ms' }}>
                        <div className="row">
                            <div className="col-sm-12" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                                <h2 style={{ fontWeight: 'bold' }}>Contáctanos</h2>
                                <p >Envíanos un mensaje, llámanos o chatea con nosotros. Nos encantará escucharte.</p>
                            </div>
                        </div>
                    </div>
                    <div className="container wow zoomIn faster" style={{ animationDelay: '400ms' }}>
                        <div className="row">

                            <div className="col-md-6 " style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', height: 420 }}>
                                <div className="service">
                                    <div className="icon">
                                        <img src={`assets/images/serviceslogos/email.svg`} style={{ height: 36 }} alt="" />
                                    </div>
                                    <h4>Correo Electrónico</h4>
                                    <div className="text">
                                        <address>
                                            <a href="mailto:sales@rhinoitsolutions.com">sales@rhinoitsolutions.com</a>
                                        </address>
                                    </div>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <img src={`assets/images/serviceslogos/tidio.svg`} style={{ height: 36 }} alt="" />
                                    </div>
                                    <div className="text">
                                        <address>
                                            <a href="https://www.tidio.com/talk/ldluggrwnzqyhgjvkyfglyzfqgm5yrs2" target="_blank">Live Chat</a>
                                        </address>
                                    </div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <div className="service" style={{ padding: 10 }}>
                                        <div className="icon">
                                            <img src={`assets/images/serviceslogos/call.svg`} style={{ height: 36 }} alt="" />
                                        </div>
                                        <h4>Teléfonos</h4>
                                        <div className="text">
                                            <address>
                                                <a href="tel:+57 (304) 382-6377"><abbr title="Colombia">+57</abbr> (304) 382-6377</a>
                                                <br />
                                                <a href="tel:+57 (302) 463-8689"><abbr title="Colombia">+57</abbr> (302) 463-8689</a>
                                                <br />
                                            </address>
                                        </div>
                                    </div>
                                    <div className="service" style={{ padding: 10 }}>
                                        <div className="icon">
                                            <img src={`assets/images/serviceslogos/map.svg`} style={{ height: 36 }} alt="" />
                                        </div>
                                        <h4>{'Dirección'}</h4>
                                        <div className="text">
                                            <address>
                                                {'Cll 150 No. 10 48'}
                                                <br /> {'Bogotá Colombia'}
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 illustration" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 420 }}>
                                <img src={`assets/images/illustrations/contactus.svg`} style={{ height: 420, maxWidth: '80%' }} alt="" />
                            </div>
                        </div>
                    </div>

                </section>
            </div >
        </div>
    )
}

export default Contact;
import React from 'react';

function Footer() {
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h4 style={{ color: '#FFF', margin: 0 }}>{`Siguenos en`}</h4>
                            <ul className="icons-widget" style={{ marginLeft: 10 }}>
                                <li>
                                    <a href="https://www.linkedin.com/company/rhino-solutions-sas" target="_blank">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="" target="_blank">
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="" target="_blank">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                            <p className="copy">
                                {`Fotografias por`}
                            </p>
                            <a style={{ backgroundColor: 'black', color: 'white', textDecoration: 'none', marginLeft: 10, padding: '4px 6px', fontFamily: "-apple-system, BlinkMacSystemFont, 'San Francisco', 'Helvetica Neue', Helvetica, Ubuntu, Roboto, Noto, 'Segoe UI', Arial, sans-serif", fontSize: 12, fontWeight: 'bold', lineHeight: '1.2', display: 'inline-block', borderRadius: 3 }}
                                href="https://unsplash.com/@grohsfabian?utm_medium=referral&utm_campaign=photographer-credit&utm_content=creditBadge"
                                target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Fabian Grohs">
                                <span style={{ display: 'inline-block', padding: '2px 3px' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{ height: 12, width: 'auto', position: 'relative', verticalAlign: 'middle', top: -2, fill: 'white' }}
                                        viewBox="0 0 32 32">
                                        <title>unsplash-logo</title>
                                        <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path>
                                    </svg>
                                </span>
                                <span style={{ display: 'inline-block', padding: '2px 3px' }}>Fabian Grohs</span>
                            </a>
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
                            <p className="copy">
                                {`© ${new Date().getFullYear()} `}
                            </p>
                            <img src={`assets/images/logo/RhinoSolsNewTextLightBlue.svg`} style={{ height: 36 }} />
                            <p className="copy">
                                {` Todos los derechos reservados.`}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import React, { useEffect } from 'react';
import Intro from './rhinoflow/Intro'
import MainHeader from './home/MainHeader'
import Services from './rhinoflow/Services'
import Products from './home/Products'
import Technologies from './home/Technologies'
import Preloader from './home/Preloader'
import ScrollUp from './home/ScrollUp'
import About from './rhinoflow/About'
import Plans from './rhinoflow/Plans'
import Clients from './home/Clients'
import Footer from './home/Footer'
import Team from './home/Team'
import Contact from './home/Contact'

function RhinoFlow() {
  const scrollUpCheck = () => {
    if (window.$(window).scrollTop() > 100) {
      window.$('.scroll-up').fadeIn();
    } else {
      window.$('.scroll-up').fadeOut();
    }
  }

  const scrollTopFnc = () => {
    var navbar = window.$('.navbar');
    var footer = window.$('#footer');
    var navHeight = navbar.height();
    if (window.scrollY >= navHeight) {
      navbar.addClass('navbar-color');
      navbar.addClass('navbar-color-rhinoflow');
      footer.addClass('footer-rhinoflow')
    }
    else {
      navbar.removeClass('navbar-color');
      navbar.removeClass('navbar-color-rhinoflow');
      footer.removeClass('footer-rhinoflow')
    }
  }


  const resizeFnc = () => {
    var navbar = window.$('.navbar');
    if (window.innerWidth <= 767) {
      navbar.addClass('custom-collapse');
      navbar.addClass('custom-collapse-rhinoflow');
    }
    else {
      navbar.removeClass('custom-collapse');
      navbar.removeClass('custom-collapse-rhinoflow');
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    window.$('#intro').backstretch([
      '/assets/images/bglogin15.svg',
      // '/assets/images/Spectrum-Gradient.svg',
      // '/assets/images/Abstract-Envelope.svg',
      // '/assets/images/Sun-Tornado.svg',
      // '/assets/images/1.jpg',
      // '/assets/images/2.jpg',
      // '/assets/images/3.jpg',
    ], { duration: 8000, fade: 1000 });

    resizeFnc();

    window.$('body').scrollspy({
      target: '.navbar-custom',
      offset: 70
    })

    var navbar = window.$('.navbar');
    var navHeight = navbar.height();

    window.addEventListener('scroll', scrollTopFnc);

    // window.$(window).scroll(function () {
    //   if (window.$(this).scrollTop() >= navHeight) {
    //     navbar.addClass('navbar-color');
    //   }
    //   else {
    //     navbar.removeClass('navbar-color');
    //   }
    // });

    if (window.$(window).width() <= 767) {
      navbar.addClass('custom-collapse');
    }

    window.$(window).resize(function () {
      if (window.$(this).width() <= 767) {
        navbar.addClass('custom-collapse');
      }
      else {
        navbar.removeClass('custom-collapse');
      }
    });

    window.$(document).on('click', '.navbar-collapse.in', function (e) {
      if (window.$(e.target).is('a') && window.$(e.target).attr('class') !== 'dropdown-toggle') {
        window.$(this).collapse('hide');
      }
    });

    window.$('a[href*=#]').bind("click", function (e) {
      var anchor = window.$(this);
      if (window.$(anchor.attr('href')).offset()) {
        window.$('html, body').stop().animate({
          scrollTop: window.$(anchor.attr('href')).offset().top
        }, 1000);
      }
      e.preventDefault();
    });

    scrollUpCheck();
    window.$(window).scroll(function () {
      scrollUpCheck();
    });

    let wow = new window.WOW({
      mobile: true
    });
    wow.init();
    window.$('#preloader').fadeOut('slow');

    return () => {
      window.scrollTo(0, 0)
      scrollTopFnc();
      var navbar = window.$('.navbar');
      navbar.removeClass('custom-collapse-rhinoflow');
      window.removeEventListener('scroll', scrollTopFnc)
      window.removeEventListener('resize', resizeFnc)
    };

  }, [])

  return (

    <div>
      <Preloader></Preloader>
      <ScrollUp></ScrollUp>
      <div style={{ overflow: 'hidden' }}>
        <Intro></Intro>
        <Services></Services>
        <About></About>
      </div>
      <Plans />
      {/* <Products></Products>
      <Technologies></Technologies>
      <Clients></Clients>
      <Team></Team> */}
    </div>

  );
}

export default RhinoFlow;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import App from './App';
import RhinoFlow from './RhinoFlow';
// import Messaging from './Messaging';
// import Automation from './Automation';
// import Integrations from './Integrations';
// import OTP from './otp';

const Main = () => {
  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' >
        <App/>
      </Route>
      <Route exact path='/rhinoflow' >
        <RhinoFlow/>
      </Route>
      {/* <Route exact path='/automation' >
        <Automation></Automation>
      </Route>
      <Route exact path='/integrations' >
        <Integrations></Integrations>
      </Route>
      <Route exact path='/otp' >
        <OTP></OTP>
      </Route> */}
    </Switch>
  );
}

export default Main;
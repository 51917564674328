import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/owl.theme.css';
import './assets/css/owl.carousel.css';
import './assets/css/animate.css';
import './assets/css/font-awesome.css';
import './assets/css/app.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Main from './Main.js'
import MainHeader from './home/MainHeader'
import Footer from './home/Footer'
import Contact from './home/Contact'
import PerfectScrollbar from 'react-perfect-scrollbar'

ReactDOM.render(
  <BrowserRouter>
    <MainHeader></MainHeader>
    <Main />
    <div style={{ overflow: 'hidden' }}>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  </BrowserRouter>
  ,
  document.getElementById('reactRoot')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect } from 'react'
function Technologies() {

    // let refreshId = -1;
    // let innerWidth = window.innerWidth;
    // let innerHeight = window.innerHeight;

    // const refreshParallax = () => {
    //     if (refreshId === -1) {

    //         window.setTimeout(() => {
    //             if (window.innerWidth !== innerWidth || window.innerHeight !== innerHeight)
    //                 refreshId = window.setTimeout(() => {
    //                     window.$('#technologies-header').parallax('refresh');
    //                     window.$('#technologies-header').parallax('render');
    //                     refreshId = -1;
    //                     innerWidth = window.innerWidth;
    //                     innerHeight = window.innerHeight;
    //                 }, 100)
    //         }, 200)
    //     }
    // }

    useEffect(() => {
        window.$("#owl-technologies").owlCarousel({
            items: 3,
            navigation: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            pagination: false,
            autoPlay: 5000,
            singleItem: false
        });

        // window.$('#technologies-header').parallax({ src: `assets/images/back (4).jpg` })

        // window.addEventListener("resize", refreshParallax);
        // window.addEventListener("scroll", refreshParallax);
    });

    return (
        <div>
            {/* <section id="technologies-header" className="section-header">
                <div className="color-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 style={{ fontWeight: 'bold' }}>Tecnologías</h2>
                                <p >Algunas de las tecnologías usadas con las cuales hemos dado valor a nuestros clientes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section id="technologies" className="section rotate-section">
                <div className="container  wow fadeInUp faster" style={{ animationDelay: '400ms' }}>
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <h2 style={{ fontWeight: 'bold' }}>Tecnologías</h2>
                            <p >Algunas de las tecnologías usadas con las cuales hemos dado valor a nuestros clientes.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 wow zoomIn faster" style={{ height: 420, display: 'flex', alignItems: 'center', animationDelay: '800ms' } } >
                            <div id="owl-technologies" className="owl-carousel">
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/react.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/angular2.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/html5.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/bootstrap.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/microsoftazure.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/netcore.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/netframework.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/microsoft-sql-server.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/mysql.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/oracle-logo.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/mongodb.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                                <div key={Math.random()} className="owl-item">
                                    <img src={`assets/images/serviceslogos/slack-logo-2019.svg`} style={{ height: 80, maxWidth: '70%' }} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 illustration wow fadeIn" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 420 }}>
                            <img src={`assets/images/illustrations/pairprograming.svg`} style={{ maxWidth: '80%' }} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Technologies;
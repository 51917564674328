import React, { useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";

function MainHeader() {
    let location = useLocation();
    useEffect(() => {
        window.$('body').scrollspy({
            target: '.navbar-custom',
            offset: 70
        })
    })

    return (
        <nav className="navbar navbar-custom navbar-fixed-top" role="navigation">
            <div className="container animated fadeInDown fast" style={{ animationDelay: '1000ms' }}>
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#custom-navbar-collapse-1">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    {location.pathname === "/rhinoflow" &&
                        <Link to="/" className="navbar-brand" style={{ paddingTop: 15 }}>
                            <div style={{ float: 'left', marginTop: -21 }}>
                                <img src={`/assets/images/logo/RhinoFlowTextNewwhite.svg`} alt="Rhino Solutions" />
                            </div>
                        </Link>
                    }
                    {location.pathname !== "/rhinoflow" &&
                        <Link to="/" className="navbar-brand" style={{ paddingTop: 15 }}>
                            <div style={{ float: 'left', marginTop: -21 }}>
                                <img src={`/assets/images/logo/RhinoSolsNewTextLightBlue.svg`} alt="Rhino Solutions" />
                            </div>
                        </Link>
                    }
                </div>

                <div className="collapse navbar-collapse" id="custom-navbar-collapse-1">
                    <ul className="nav navbar-nav navbar-right" style={{ fontWeight: 'bold', textShadow: 'rgb(0, 0, 0) 7px 1px 20px' }}>
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <a href="#products">Productos</a>
                                {/* {'Productos'} */}
                            </span>
                            <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                <ul className="nav">
                                    {location.pathname !== "/" &&
                                        <li>
                                            <Link to="/">
                                                <img src={`assets/images/logo/RhinoSolsNew.svg`} style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                {' Página principal'}
                                                <p>Volver a la página principal</p>
                                            </Link>
                                        </li>
                                    }
                                    {location.pathname !== "/rhinoflow" &&
                                        <li>
                                            <Link to="/rhinoflow">
                                                <img src={`assets/images/logo/RhinoApp.svg`} alt="RhinoFlow BPM" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                {' RhinoFlow BPM'}
                                                <p>Automatice procesos BPM con RhinoFlow</p>
                                            </Link>
                                        </li>
                                    }
                                    {/* 
                                    {location.pathname !== "/dev" &&
                                        <li>
                                            <Link to="/dev">
                                                <img src={`assets/images/logo/code.svg`} alt="OTP" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                {' Desarrollo de Software'}
                                                <p>Desarrollo de software bajo Agile CI</p>
                                            </Link>
                                        </li>
                                    }

                                    {location.pathname !== "/azure" &&
                                        <li>
                                            <Link to="/dev">
                                                <img src={`assets/images/serviceslogos/microsoftlogo.svg`} alt="CI/CD" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                {' Azure CI/CD'}
                                                <p>Consultoria Azure CI/CD</p>
                                            </Link>
                                        </li>
                                    } */}

                                </ul>
                            </div>
                        </li>
                        {location.pathname === "/" &&
                            <React.Fragment>
                                <li>
                                    <a href="#technologies">Tecnolog&iacute;as</a>
                                </li>
                                <li>
                                    <a href="#about">Nosotros</a>
                                </li>
                                <li>
                                    <a href="#clients">Clientes</a>
                                </li>
                                <li>
                                    <a href="#team">Equipo</a>
                                </li>
                            </React.Fragment>
                        }
                        {location.pathname === "/rhinoflow" &&
                            <React.Fragment>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <a href="#products">Recursos</a>
                                    </span>
                                    <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                        <ul className="nav">
                                            {location.pathname !== "/" &&
                                                <li>
                                                    <a href="https://rhinoflowdocs.rhinoitsolutions.com" rel="noopener noreferrer" target="_blank">
                                                        <img src={`assets/images/serviceslogos/docs.svg`} alt="Portal de Documentación" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                        {' Portal de Documentación'}
                                                        <p>Explore la documentación de RhinoFlow</p>
                                                    </a>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#services">Servicios</a>
                                </li>
                                <li>
                                    <a href="#about">Funcionalidades</a>
                                </li>
                                <li>
                                    <a href="#plans">Planes</a>
                                </li>
                            </React.Fragment>
                        }
                        <li>
                            <a href="#contact">Contáctanos</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav >
    );
}
export default MainHeader
import React from 'react'

function Preloader() {
    return (
        <div id="preloader">
            <div id="status">
                <div className="status-mes" >
                </div>
            </div>
        </div>
    );
}

export default Preloader
